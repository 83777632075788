<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaProposta } from '@/proxy-api/src/services/classes/Proposta/ConsultaProposta';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormProposta from '@/views/proposta/form/FormProposta';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaProposta(),
      formCadastro: FormProposta,
    };
  },
};
</script>
